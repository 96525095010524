<template>
  <div class="soil-list">
    <van-nav-bar
      title="取土记录"
      left-text="返回"
      left-arrow
      @click-left="onNavClick"
    />
    <div class="soil-list-wrap">
      <div class="title">
        <div class="add-btn" @click="addSoil">
          <van-button icon="plus" type="info" size="small">新增</van-button>
        </div>
      </div>
      <table class="thead">
        <thead>
          <tr>
            <th width="20%">序号</th>
            <th width="40%">土样编号</th>
            <th width="40%">取土时间</th>
          </tr>
        </thead>
      </table>
      <table class="tbody">
        <tbody v-if="soilList.length > 0">
          <tr v-for="(item, index) in soilList" :key="index">
            <td width="20%">{{ index + 1 }}</td>
            <td width="40%">{{ item.soil_code }}</td>
            <td width="40%">{{ item.soil_date }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="3">暂无取土记录</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="my-vant-dialog">
      <van-dialog
        v-model="addSoilDialogShow"
        title="新增取土"
        show-cancel-button
        :beforeClose="addSoilDialogClose"
      >
        <div class="dialog-soil-wrap">
          <van-form ref="myForm">
            <van-field
              v-model="takeSoilTime"
              required
              readonly
              clickable
              validate-trigger="onSubmit"
              name="取土时间"
              label="取土时间"
              placeholder="取土时间"
              @click="showPicker = true"
              :rules="[{ required: true, message: '请选择时间' }]"
            />
            <van-field
              v-model="takeSoilCode"
              required
              validate-trigger="onSubmit"
              name="取土编号"
              label="取土编号"
              placeholder="取土编号"
              :rules="[{ required: true, message: '请填写编号' }]"
            />
          </van-form>
        </div>
      </van-dialog>
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="datePickValue"
        title="选择年月日"
        @confirm="dateConfirm"
        @cancel="dateCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  getFieldSoil,
  postAddFieldSoil
} from "@/service/serverPage/myField.js";
import dayjs from "dayjs";
import {
  Button,
  Dialog,
  NavBar,
  Form,
  Field,
  Popup,
  DatetimePicker,
  Notify
} from "vant";
export default {
  name: "SoilList",
  components: {
    "van-button": Button,
    "van-dialog": Dialog.Component,
    "van-nav-bar": NavBar,
    "van-form": Form,
    "van-field": Field,
    "van-popup": Popup,
    "van-datetime-picker": DatetimePicker
  },
  props: ["id"],
  data() {
    return {
      soilList: [],
      addSoilDialogShow: false,
      takeSoilTime: "",
      takeSoilCode: "", // 表单的时间数据
      datePickValue: "", // datepicker的时间数据
      showPicker: false
    };
  },
  created() {
    this.getFieldSoilList()
  },
  methods: {
    onNavClick() {
      // 返回操作
      this.$router.go(-1);
    },
    getFieldSoilList() {
      getFieldSoil(this.id).then((res) => {
        this.soilList = res.data;
      });
    },
    addSoil() {
      // 设置表单初始化取土时间
      this.takeSoilTime = dayjs().format("YYYY-MM-DD");
      // datepicker设置时间
      this.datePickValue = dayjs().startOf('date').$d
      this.addSoilDialogShow = true;
    },
    addSoilDialogClose(action, done) {
      if (action === "confirm") {
        const pValidate = this.$refs.myForm.validate();
        pValidate
          .then(() => {
            postAddFieldSoil(this.id, this.takeSoilTime, this.takeSoilCode)
              .then((res) => {
                if (res.code === 200) {
                  // 更新取土记录list数据
                  this.getFieldSoilList()
                  Notify({ type: "success", message: "取土记录添加成功" });
                } else {
                  Notify({
                    type: "danger",
                    message: "取土记录添加失败，请重新尝试"
                  });
                }
              })
              .catch(() => {
                Notify({
                  type: "success",
                  message: "取土记录添加失败，请重新尝试"
                });
              });
            done();
            // 初始化
            this.takeSoilTime = "";
            this.takeSoilCode = "";
            this.$refs.myForm.resetValidation();
          })
          .catch(() => {
            done(false);
          });
      } else if (action === "cancel") {
        done();
        // 初始化
        this.takeSoilTime = "";
        this.takeSoilCode = "";
        this.$refs.myForm.resetValidation();
      }
    },
    dateConfirm(value) {
      this.showPicker = false;
      this.takeSoilTime = dayjs(value).format("YYYY-MM-DD");
    },
    dateCancel() {
      this.showPicker = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.soil-list {
  box-sizing: border-box;
  .soil-list-wrap {
    margin: 0 20px;
    color: #333;
    .title {
      font-size: 22px;
      font-weight: 600;
      text-align: right;
      margin: 0 0 6px;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    table.thead,
    table.tbody {
      width: 100%;
      th,
      td {
        text-align: center;
        border: 1px solid #ddd;
        padding: 10px 0;
      }
      th {
        border-bottom: none;
      }
    }
  }
}
.my-vant-dialog {
  .dialog-soil-wrap {
    margin: 40px 20px;
  }
}
</style>
